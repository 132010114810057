@import "~react-image-gallery/styles/css/image-gallery.css";

body {
  background-color: #fafafa;
  font-family: "Source Serif Pro", serif;
}

body > div {
  margin-bottom: 4rem;
}

.btn-primary {
  margin: 0.5rem 0rem 1rem 0rem;
}

.image-container,
.button-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.image-container > img {
  width: 409px;
}
